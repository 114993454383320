import { useMediaQuery } from "react-responsive";
import AdaptiveImg from "../AdaptiveImg/AdaptiveImg";
import Slider from "../Complexes/Slider";
import Icons from "../Icons/Icons";
import { t } from "i18next";
import BravoAccordion from "../BravoMainSection/BravoAccordion";
import SpecList from "../BravoMainSection/SpecList";
import ConsultationBtn from "../Buttons/ConsultationBtn";

const BravoProductItem = ({ content }) => {
  const notMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isDesctop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  return (
    <>
      {!isDesctop && (
        <h2 id={content.name} className="">
          <span className="section-title text-myRed">
            {content.title.accent}
          </span>
          <br />
          <span className="uppercase font-bold">{content.title.regular}</span>
        </h2>
      )}
      <div className="py-5 md:flex gap-[10px] mobOnly:h-[calc((100vw-40px)*(285/440)+40px)] h-[325px] md:h-[240px] xl:h-[410px] xl:gap-5">
        {notMobile ? (
          <>
            <div className="md::max-w-[calc(50%-10px)] shrink-0">
              <AdaptiveImg full={true} imgName={`${content.img}`} />
            </div>
            <div className="md::max-w-[calc(50%-10px)] shrink-0">
              <AdaptiveImg imgName={`${content.img}-scheme`} />
            </div>
          </>
        ) : (
          <Slider>
            <AdaptiveImg imgName={`${content.img}`} />
            <AdaptiveImg imgName={`${content.img}-scheme`} />
          </Slider>
        )}
      </div>
      <div className="flex flex-col gap-5 md:flex-row-reverse md:gap-[10px]">
        <div className="md:max-w-[298px] xl:max-w-[375px] shrink-0">
          <p className="flex flex-col gap-[10px] md:gap-10">
            <span className="text-[18px] xl:text-[20px] uppercase font-semibold">
              {content.description.accent}
            </span>
            <span>{content.description.regular}</span>
          </p>
        </div>
        <div className="p-5 xl:p-10 grow rounded-[8px] xl:rounded-[15px] bg-myLightGray">
          <h5 className="uppercase text-[16px] xl:text-[20px] font-semibold border-b-[#DFDDDD] border-b pb-5 xl:pb-[10px] mb-5 xl:mb-10">
            {content.techBlock.title}
          </h5>
          <SpecList spec={content.techBlock.specList} />
          {content.techBlock.bottomText &&
            content.techBlock.bottomText !== "" && (
              <p className="flex gap-2 xl:gap-3 mt-5 xl:mt-10 items-center">
                <span>
                  <Icons
                    className="w-6 h-6"
                    name={content.techBlock.iconName}
                  />
                </span>
                <span>{content.techBlock.bottomText}</span>
              </p>
            )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 md:gap-[10px] md:items-end mt-[10px] xl:mt-5">
        <BravoAccordion title={content.advantagesBlock.title}>
          <SpecList spec={content.advantagesBlock.advantagesList} gap={true} />
        </BravoAccordion>
        <div className="w-full xl:flex xl:flex-col xl:justify-between xl:self-stretch bg-myBlack text-myWhite rounded-[8px] xl:rounded-[15px] md:max-w-[298px] p-5 xl:p-10 xl:max-w-[375px] shrink-0">
          <div>
            <p className="uppercase text-[20px] font-semibold mb-8 xl:mb-5">
              {t("footer.contacts")}
            </p>
            <a
              className="flex gap-6 items-center mb-[18px] xl:mb-3"
              href="tel:+380960382626"
            >
              <span>
                <Icons name="phoneWhite" className="w-6 h-6" />
              </span>
              <span>+380 (096) 038-26-26</span>
            </a>
            <a
              className="flex gap-6 items-center mb-10"
              href="https://www.instagram.com/bravohitch?igsh=MTI2aGV1cGcyODVtZA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <Icons name="instaWhite" className="w-6 h-6" />
              </span>
              <span>@bravohitch</span>
            </a>
          </div>
          <div className="flex flex-col gap-3 xl:gap-5">
            <ConsultationBtn className="xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300" />
            {/* <a
              className="w-full py-[15px] px-[60px] bg-myRed rounded-[15px] text-center text-[16px] xl:text-[18px] text-myWhite"
              href="https://t.me/bravo_hitch"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("buttons.consultation")}
            </a> */}
            <a
              className="w-full py-[15px] px-[15px] rounded-[15px] text-center text-[16px] xl:text-[18px] text-myWhite border border-myWhite xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300"
              href="https://docs.google.com/spreadsheets/d/1jFsDDw1qDK6xpkEwcxZqEG3311Mi00SLZCwHCncfRhs/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("buttons.price")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BravoProductItem;

import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Bravo from "./pages/Bravo/Bravo";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bravo" element={<Bravo />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default App;

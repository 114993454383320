import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import Accordion from "./Accordion";
import TechList from "./TechList";
import Slider from "./Slider";
import AdaptiveImg from "../AdaptiveImg/AdaptiveImg";
import { useMediaQuery } from "react-responsive";
import { useRef, useState } from "react";
import ComplexesMenu from "./ComplexesMenu";
import ConsultationBtn from "../Buttons/ConsultationBtn";
import LoadPriceBtn from "../Buttons/LoadPriceBtn";
import { CSSTransition } from "react-transition-group";

const Complexes = () => {
  const { t } = useTranslation();
  const [activeComplex, setActiveComplex] = useState("vodohray2");
  const notMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isDesctop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const btnAction = (e) => {
    setActiveComplex(e.currentTarget.name);
  };
  const {
    techParams,
    equip1,
    equip2,
    equip1eco,
    equip2eco,
    boxName,
    box2Name,
  } = t("complexes");
  const node1Ref = useRef(null);
  const node2Ref = useRef(null);
  const node3Ref = useRef(null);
  const node4Ref = useRef(null);

  return (
    <section id="complexes" className="pt-[80px] md:pt-[100px]">
      <Container>
        {isDesctop && (
          <ComplexesMenu btnAction={btnAction} activeComplex={activeComplex} />
        )}
        <CSSTransition
          nodeRef={node1Ref}
          in={activeComplex === "vodohray1" || !isDesctop}
          timeout={{
            enter: 500,
            exit: 0,
          }}
          classNames="my-node"
          unmountOnExit
        >
          <article ref={node1Ref}>
            {!isDesctop && (
              <p className="section-title text-myRed uppercase mb-5">
                vodohray 1
              </p>
            )}
            {!notMobile ? (
              <Slider>
                <AdaptiveImg imgName="vodogray-1-out" />
                <AdaptiveImg imgName="vodogray-1-in" />
              </Slider>
            ) : (
              <div className="flex gap-[10px] xl:gap-5">
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-1-out" full={true} />
                </div>
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-1-in" full={true} />
                </div>
              </div>
            )}
            <div className="mt-5 xl:mt-10">
              <Accordion text={boxName}>
                <TechList left={equip1.left} right={equip1.right} mod={true} />
              </Accordion>
              <div className="xl:flex xl:gap-5 mt-[10px] xl:mt-5">
                <Accordion text={box2Name} tech={true}>
                  <TechList left={techParams.left} right={techParams.right} />
                </Accordion>
                <div className="flex flex-col gap-[10px] mt-10 md:flex-row xl:flex-col xl:rounded-[15px] xl:bg-myLightGray xl:mt-0 xl:items-center xl:justify-center xl:grow">
                  <ConsultationBtn className="xl:max-w-[300px] xl:hover:bg-myBlack transition-colors ease-in-out duration-300" />
                  <LoadPriceBtn />
                </div>
              </div>
            </div>
          </article>
        </CSSTransition>
        <CSSTransition
          nodeRef={node2Ref}
          in={activeComplex === "vodohray1eco" || !isDesctop}
          timeout={{
            enter: 500,
            exit: 0,
          }}
          classNames="my-node"
          unmountOnExit
        >
          <article ref={node2Ref} className="mt-[80px] md:mt-[50px] xl:mt-0">
            {!isDesctop && (
              <p className="section-title text-myRed uppercase mb-5 flex flex-col">
                <span>vodohray 1</span>
                <span className="text-[14px]">econom</span>
              </p>
            )}
            {!notMobile ? (
              <Slider>
                <AdaptiveImg imgName="vodogray-1-econom-out" />
                <AdaptiveImg imgName="vodogray-1-econom-in" />
              </Slider>
            ) : (
              <div className="flex gap-[10px] xl:gap-5">
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-1-econom-out" full={true} />
                </div>
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-1-econom-in" full={true} />
                </div>
              </div>
            )}
            <div className="mt-5 xl:mt-10">
              <Accordion text={boxName}>
                <TechList
                  left={equip1eco.left}
                  right={equip1eco.right}
                  mod={true}
                />
              </Accordion>
              <div className="xl:flex xl:gap-5 mt-[10px] xl:mt-5">
                <Accordion text={box2Name} tech={true}>
                  <TechList left={techParams.left} right={techParams.right} />
                </Accordion>
                <div className="flex flex-col gap-[10px] mt-10 md:flex-row xl:flex-col xl:rounded-[15px] xl:bg-myLightGray xl:mt-0 xl:items-center xl:justify-center xl:grow">
                  <ConsultationBtn className="xl:max-w-[300px] xl:hover:bg-myBlack transition-colors ease-in-out duration-300" />
                  <LoadPriceBtn />
                </div>
              </div>
            </div>
          </article>
        </CSSTransition>
        <CSSTransition
          nodeRef={node3Ref}
          in={activeComplex === "vodohray2" || !isDesctop}
          timeout={{
            enter: 500,
            exit: 0,
          }}
          classNames="my-node"
          unmountOnExit
        >
          <article ref={node3Ref} className="mt-[80px] md:mt-[50px] xl:mt-0">
            {!isDesctop && (
              <p className="section-title text-myRed uppercase mb-5">
                vodohray 2
              </p>
            )}
            {!notMobile ? (
              <Slider>
                <AdaptiveImg imgName="vodogray-2-out" />
                <AdaptiveImg imgName="vodogray-2-part" />
              </Slider>
            ) : (
              <div className="flex gap-[10px] xl:gap-5">
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-2-out" full={true} />
                </div>
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-2-part" full={true} />
                </div>
              </div>
            )}
            <div className="mt-5 xl:mt-10">
              <Accordion text={boxName}>
                <TechList left={equip2.left} right={equip2.right} mod={true} />
              </Accordion>
              <div className="xl:flex xl:gap-5 mt-[10px] xl:mt-5">
                <Accordion text={box2Name} tech={true}>
                  <TechList left={techParams.left} right={techParams.right} />
                </Accordion>
                <div className="flex flex-col gap-[10px] mt-10 md:flex-row xl:flex-col xl:rounded-[15px] xl:bg-myLightGray xl:mt-0 xl:items-center xl:justify-center xl:grow">
                  <ConsultationBtn className="xl:max-w-[300px] xl:hover:bg-myBlack transition-colors ease-in-out duration-300" />
                  <LoadPriceBtn />
                </div>
              </div>
            </div>
          </article>
        </CSSTransition>
        <CSSTransition
          nodeRef={node4Ref}
          in={activeComplex === "vodohray2eco" || !isDesctop}
          timeout={{
            enter: 500,
            exit: 0,
          }}
          classNames="my-node"
          unmountOnExit
        >
          <article ref={node4Ref} className="mt-[80px] md:mt-[50px] xl:mt-0">
            {!isDesctop && (
              <p className="section-title text-myRed uppercase mb-5 flex flex-col">
                <span>vodohray 2</span>
                <span className="text-[14px]">econom</span>
              </p>
            )}
            {!notMobile ? (
              <Slider>
                <AdaptiveImg imgName="vodogray-2-econom-out" />
                <AdaptiveImg imgName="vodogray-2-econom-in" />
              </Slider>
            ) : (
              <div className="flex gap-[10px] xl:gap-5">
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-2-econom-out" full={true} />
                </div>
                <div className="w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="vodogray-2-econom-in" full={true} />
                </div>
              </div>
            )}
            <div className="mt-5 xl:mt-10">
              <Accordion text={boxName}>
                <TechList
                  left={equip2eco.left}
                  right={equip2eco.right}
                  mod={true}
                />
              </Accordion>
              <div className="xl:flex xl:gap-5 mt-[10px] xl:mt-5">
                <Accordion text={box2Name} tech={true}>
                  <TechList left={techParams.left} right={techParams.right} />
                </Accordion>
                <div className="flex flex-col gap-[10px] mt-10 md:flex-row xl:flex-col xl:rounded-[15px] xl:bg-myLightGray xl:mt-0 xl:items-center xl:justify-center xl:grow">
                  <ConsultationBtn className="xl:max-w-[300px] xl:hover:bg-myBlack transition-colors ease-in-out duration-300" />
                  <LoadPriceBtn />
                </div>
              </div>
            </div>
          </article>
        </CSSTransition>
      </Container>
    </section>
  );
};
export default Complexes;

import { useState } from "react";
import Icons from "../Icons/Icons";
import { useMediaQuery } from "react-responsive";

const Accordion = ({ children, text, tech }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDecktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const btnAction = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div
      className={`rounded-[15px] bg-myLightGray py-6 px-5 md:p-5 xl:p-10 xl:flex xl:gap-[66px] ${
        isOpen ? "pb-[30px] md:pb-10" : ""
      } ${tech ? "xl:w-[767px]" : ""}`}
    >
      <div className="relative">
        <p className="text-[18px] font-semibold uppercase xl:w-[185px]">
          {text}
        </p>
        {!isDecktop && (
          <button
            className="absolute top-0 right-[-7px]"
            onClick={btnAction}
            type="button"
            aria-label="toggle accordion"
          >
            <Icons
              name={isOpen ? "cross" : "arrow"}
              className="w-8 h-8 stroke-myBlack"
            />
          </button>
        )}
      </div>
      {(isOpen || isDecktop) && children}
    </div>
  );
};
export default Accordion;

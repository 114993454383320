import { useTranslation } from "react-i18next";
import { useModal } from "../Context/ModalContext";

const ConsultationBtn = ({ className }) => {
  const { t } = useTranslation();
  const { setOpen } = useModal();
  return (
    <button
      onClick={setOpen}
      className={`w-full py-[15px] px-[60px] bg-myRed rounded-[15px] text-[16px] xl:text-[18px] text-myWhite ${
        className ? className : ""
      }`}
    >
      {t("buttons.consultation")}
    </button>
  );
};

export default ConsultationBtn;

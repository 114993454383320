import Icons from "../Icons/Icons";

const MenuButton = ({ clickAction }) => {
  return (
    <button
      onClick={clickAction}
      type="button"
      aria-label="burger menu"
      className="w-12 h-full"
    >
      <Icons name="burger" className="w-12" />
    </button>
  );
};
export default MenuButton;

import { useTranslation } from "react-i18next";

const BravoTabNav = ({ tabClickAction, activeTab }) => {
  const { t } = useTranslation();
  const tabNavItems = t("bravoSection.tabNav");
  return (
    <ul className="flex justify-between">
      {tabNavItems &&
        tabNavItems.map((item) => (
          <li
            onClick={() => tabClickAction(item.name)}
            key={item.name}
            className={`shrink-0 cursor-pointer group pb-[12px] border-b-2 ${
              activeTab === item.name
                ? "border-b-myBlack"
                : " border-b-transparent"
            }`}
          >
            <h2 className="font-bold">
              <span
                className={`text-[32px] flex items-baseline group-hover:text-myRed ${
                  activeTab === item.name ? "text-myRed" : "text-myGray"
                }`}
              >
                {item.name === "BRAVO - S mini" ? (
                  <>
                    <span>BRAVO - S</span>
                    <span className="text-[14px]">MINI</span>
                  </>
                ) : (
                  item.name
                )}
              </span>
              <span
                className={`max-w-[160px] block text-[12px] uppercase group-hover:text-myBlack ${
                  activeTab === item.name ? "text-myBlack" : "text-myGray"
                }`}
              >
                {item.desc}
              </span>
            </h2>
          </li>
        ))}
    </ul>
  );
};

export default BravoTabNav;

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import BravoProductItem from "./BravoProductItem";

const BravoProductsList = ({ activeTab }) => {
  const isDesctop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const { t } = useTranslation();
  const tabsContent = t("bravoSection.tabItems");
  return (
    <ul>
      {tabsContent &&
        tabsContent.map(
          (item) =>
            (!isDesctop || activeTab === item.name) && (
              <li key={item.name} className=" notXl:mb-[60px] notXl:last:mb-0">
                <BravoProductItem content={item} />
              </li>
            )
        )}
    </ul>
  );
};

export default BravoProductsList;

import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";
import { useMediaQuery } from "react-responsive";
import ConsultationBtn from "../Buttons/ConsultationBtn";
import { useInView } from "react-intersection-observer";
import BravoPopUp from "../BravoPopUp/BravoPopUp";

const IndividualOrders = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const { t } = useTranslation();
  const { title, subtitle, options, bottomText, textBlock, cta } =
    t("individualOrders");
  const isDesktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  return (
    <>
      <section className="py-[80px] md:py-[100px]">
        <Container>
          <div className="flex flex-col gap-5 mb-10 xl:mb-[60px] md:flex-row justify-between md:gap-0">
            <h2 className="section-title text-myRed md:w-[300px]">{title}</h2>
            <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px] xl:pr-[100px]">
              <span className="section-subtitle-accent">
                {subtitle.accent1}
              </span>
              {!isDesktop && <br />}
              {subtitle.regular1}
              <span className="section-subtitle-accent">
                {subtitle.accent2}
              </span>
              {subtitle.regular2}
            </h3>
          </div>
          <div className="xl:flex gap-5 xl:mb-[60px]">
            {isDesktop && (
              <div className="flex flex-col justify-between">
                <div className="py-[50px] pl-10 pr-[200px] rounded-[15px] bg-myBlack text-myWhite flex flex-col gap-[10px]">
                  <h4 className="font-bold mb-[25px] uppercase">
                    {textBlock.title}
                  </h4>
                  {textBlock.texts.map((el, idx) => (
                    <p key={idx}>{el}</p>
                  ))}
                </div>
                <p className="section-subtitle w-[430px]">
                  <span className="section-subtitle-accent">
                    {bottomText.accent1}
                  </span>
                  {bottomText.regular1}
                  <span className="section-subtitle-accent">
                    {bottomText.accent2}
                  </span>
                </p>
              </div>
            )}

            <ul className="flex flex-col gap-[10px] mb-10 xl:w-[472px] xl:shrink-0 xl:mb-0">
              {options.map((el, idx) => (
                <li
                  className="p-5 rounded-[10px] xl:rounded-[15px] bg-myLightGray flex gap-[30px] items-center"
                  key={idx}
                >
                  <Icons name="check" className="w-10 h-10 shrink-0" />
                  <p className="text-[16px]">{el}</p>
                </li>
              ))}
            </ul>
          </div>
          {!isDesktop && (
            <p className="section-subtitle mb-10 md:w-[calc(50%-5px)]">
              <span className="section-subtitle-accent">
                {bottomText.accent1}
              </span>
              {bottomText.regular1}
              <span className="section-subtitle-accent">
                {bottomText.accent2}
              </span>
            </p>
          )}
          <div className="text-center" ref={ref}>
            <Icons
              name="chevron"
              className="w-10 h-10 stroke-myBlack rotate-180 mb-5 mx-auto"
            />
            <p className="section-subtitle mb-10">{cta}</p>
            <ConsultationBtn className="max-w-[320px] xl:max-w-[300px] xl:hover:bg-myBlack transition-colors ease-in-out duration-300" />
          </div>
        </Container>
      </section>
      {inView && <BravoPopUp />}
    </>
  );
};

export default IndividualOrders;

import { useTranslation } from "react-i18next";

const LanguageChange = ({ white }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div
      className={`flex gap-[8px] ${
        white ? "text-myWhite" : "text-myBlack xl:text-myWhite"
      } text-[18px]`}
    >
      <button
        className={
          i18n.language === "ua" ||
          i18n.language === "uk" ||
          i18n.language === "uk-UA"
            ? "text-myRed underline py-[10px]"
            : "py-[10px]"
        }
        onClick={() => changeLanguage("ua")}
      >
        УКР
      </button>
      <button
        className={
          i18n.language === "en"
            ? "text-myRed underline py-[10px]"
            : "py-[10px]"
        }
        onClick={() => changeLanguage("en")}
      >
        ENG
      </button>
    </div>
  );
};
export default LanguageChange;

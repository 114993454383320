import { useTranslation } from "react-i18next";
import AdaptiveImg from "../AdaptiveImg/AdaptiveImg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Icons from "../Icons/Icons";

const BravoPopUp = () => {
  const [couldBeClose, setCouldBeClose] = useState(false);
  const [isClose, setIsClose] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCouldBeClose(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const { t } = useTranslation();
  return (
    <div
      className={`${
        isClose ? "hidden" : ""
      } fixed top-0 bottom-0 right-0 left-0 xl:left-[calc(50%+100px)] xl:right-auto xl:top-auto xl:bottom-[80px] xl:w-[480px] z-[500] bg-myBlack xl:bg-transparent bg-opacity-80 xl:bg-opacity-0 blur-[25px] xl:blur-0 filter-none`}
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 xl:static xl:left-auto xl:top-auto xl:translate-x-0 xl:translate-y-0">
        <div className="rounded-[15px] p-[10px] bg-myWhite md:p-5 w-[calc(100vw-20px)] max-w-[480px] border border-solid border-myGray shadow-[0px_4px_15px_0px_rgba(0,0,0,0.05)] ">
          <div className="h-8 w-full">
            {couldBeClose && (
              <button
                onClick={() => setIsClose(true)}
                className="block ml-auto"
                type="button"
              >
                <Icons name="cross" className="w-8 h-8" />
              </button>
            )}
          </div>
          <p className="mb-3 md:mb-5">
            <span className="section-title text-myRed">
              {t("bravoPopUp.title.accent")}
            </span>
            <br />
            <span className="uppercase font-bold">
              {t("bravoPopUp.title.regular")}
            </span>
          </p>
          <div className="mb-3">
            <AdaptiveImg full={true} imgName="bravo" />
          </div>
          <p className="text-[16px] mb-5 md:mb-10">{t("bravoPopUp.desc")}</p>
          <Link
            className="w-full text-center block px-[60px] py-[15px] rounded-[15px] bg-myBlack text-myWhite text-[18px]"
            to="/bravo"
          >
            {t("bravoPopUp.button")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BravoPopUp;

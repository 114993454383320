import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import SectionForm from "../SectionForm/SectionForm";
import { useState } from "react";
import AfterForm from "../AfterForm/AfterForm";
import AfterFormError from "../AfterForm/AfterFormError";
import Icons from "../Icons/Icons";

const ContactUs = () => {
  const [isDone, setIsDone] = useState(false);
  const { t } = useTranslation();
  return (
    <section className="py-10 bg-myBlack text-myWhite">
      <Container className="flex flex-col gap-10 md:gap-0 md:flex-row justify-between">
        <div className="md:w-[calc(50%-5px)]">
          <h2 className="section-title max-w-[380px] md:max-w-[315px] xl:max-w-[466px]">
            {t("contactUs.title")}
          </h2>
          <p className="mt-5 xl:mt-10 max-w-[340px] md:max-w-[275px]">
            {t("contactUs.textBlock")}
          </p>
        </div>
        <div className="md:w-[calc(50%-5px)] xl:w-[472px] ">
          <div
            className={`rounded-[15px] ${
              isDone
                ? "bg-myLightGray text-myBlack pt-[60px] pb-[28px] relative"
                : ""
            }`}
          >
            {isDone === "fail" && (
              <button
                onClick={() => {
                  setIsDone(false);
                }}
                aria-label="close notification"
                className="absolute top-5 right-5"
              >
                <Icons name="cross" className="w-8 h-8" />
              </button>
            )}
            {!isDone && <SectionForm setIsDone={setIsDone} />}
            {isDone === "done" && <AfterForm />}
            {isDone === "fail" && <AfterFormError />}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;

import { useTranslation } from "react-i18next";

const LoadPriceBtn = ({ className }) => {
  const { t } = useTranslation();
  return (
    <a
      href="https://docs.google.com/spreadsheets/d/1_AFnAgGHduOndkAPkz-0fMAvrhu9F9Ctp2KtOfWNR5Y/edit?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
      className={`w-full xl:max-w-[300px] py-[15px] px-[60px] bg-transparent rounded-[15px] text-center text-[16px] xl:text-[18px] text-myBlack border-solid border-myBlack border-[1px] xl:hover:bg-myBlack xl:hover:text-myWhite transition-colors ease-in-out duration-500 ${
        className ? className : ""
      }`}
    >
      {t("buttons.price")}
    </a>
  );
};

export default LoadPriceBtn;

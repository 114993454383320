import { Link } from "react-router-dom";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";
import { useMediaQuery } from "react-responsive";
import LanguageChange from "../LanguageChange/LanguageChange";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { useState } from "react";
import MenuButton from "../Buttons/MenuButton";
import { useTranslation } from "react-i18next";

const BravoHeader = ({ setBurgerIsOpen }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDecktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const menuButtonAction = () => {
    setIsMenuOpen(true);
    setBurgerIsOpen(true);
  };
  const menuCloseBtnAction = () => {
    setIsMenuOpen(false);
    setBurgerIsOpen(false);
  };
  return (
    <Container className="pt-[10px] xl:pt-5">
      <header
        id="bravoHeader"
        className="flex items-center justify-between text-[18px] text-myWhite leading-[1.2] w-full bg-myBlack rounded-[8px] py-[10px] xl:py-3 px-4"
      >
        <Link to="/">
          <Icons name="logo" className="w-[100px] xl:w-[122px]" />
        </Link>
        {isDecktop ? (
          <div className="flex gap-[60px]">
            <nav className="flex gap-[10px]">
              <Link className="p-[10px]" to="/">
                {t("pageNav.home")}
              </Link>
              <Link className="p-[10px] text-myRed font-semibold" to="/bravo">
                {t("pageNav.bravo")}
              </Link>
            </nav>
            <LanguageChange />
          </div>
        ) : (
          <>
            <BurgerMenu
              menuCloseBtnAction={menuCloseBtnAction}
              isMenuOpen={isMenuOpen}
              bravo={true}
            />
            <MenuButton clickAction={menuButtonAction} />
          </>
        )}
      </header>
    </Container>
  );
};

export default BravoHeader;

import { Link } from "react-scroll";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";
import { useTranslation } from "react-i18next";
import LanguageChange from "../LanguageChange/LanguageChange";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const { t } = useTranslation();
  const isDecktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const sectionsIds = ["complexes", "about us", "projects", "funds"];
  return (
    <footer className="bg-myBlack text-myWhite py-10 xl:py-[60px]">
      <Container>
        <div className="flex flex-row justify-between items-center mb-10 md:mb-[60px] relative">
          <div className="w-[100px] xl:w-[122px] z-30">
            <Link
              className="w-[100px] xl:w-[122px] z-40"
              to="hero"
              smooth={true}
            >
              <Icons className="w-[100px] xl:w-[122px] z-50" name="logo" />
            </Link>
          </div>
          <Link to="hero" smooth={true}>
            <Icons
              name="chevron"
              className="w-12 h-12 xl:w-[58px] xl:h-[58px] stroke-myWhite rotate-180"
            />
          </Link>
        </div>
        <div className="mb-20 md:mb-[60px] md:flex justify-between">
          <div className="mb-[60px] md:mb-0 text-center md:text-start text-[16px] xl:text-[18px] flex flex-col">
            <p className="section-title mb-10">{t("footer.contacts")}</p>
            <a className="mb-[30px] xl:mb-5" href="tel:+3800960382626">
              +380 (096) 038-26-26
            </a>
            <a
              className="mb-[30px] xl:mb-5"
              href="mailto:Welding2626@gmail.com"
            >
              Welding2626@gmail.com
            </a>
            <a
              className="flex w-[115px] xl:w-[125px] justify-between mb-10 mob:mx-auto"
              href="https://wa.me/+380970382626"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
            </a>
            <p className="section-subtitle mb-10">{t("footer.socialMedia")}</p>
            <a
              className="flex w-[115px] xl:w-[125px] justify-between mb-[30px] xl:mb-5 mob:mx-auto"
              href="https://instagram.com/welding_territory?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
            </a>
            <a
              className="flex w-[115px] xl:w-[125px] justify-between mb-[30px] xl:mb-5 mob:mx-auto"
              href="https://www.tiktok.com/@26krava?_t=8ehSDzUPDGw&_r=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              Tik Tok <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
            </a>
            <a
              className="flex w-[115px] xl:w-[125px] justify-between mob:mx-auto"
              href="https://youtube.com/@welding_territory"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
            </a>
          </div>
          <div className="text-center">
            <p className="section-title w-[215px] xl:w-auto mx-auto mb-5 xl:mb-10">
              {t("support.title")}
            </p>
            <p className="mb-[60px] max-w-[312px] mx-auto">
              {t("support.textBlock")}
            </p>
            <a
              href="https://send.monobank.ua/jar/2Q2Sfct6hX"
              target="_blank"
              rel="noreferrer noopener"
              className="w-full max-w-[320px] py-[15px] px-[60px] bg-myRed rounded-[15px] text-[16px] text-center xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300"
            >
              {t("support.button")}{" "}
            </a>
          </div>
          {isDecktop && (
            <nav>
              <ul className="flex flex-col gap-5 text-[18px]">
                <li className="section-title mb-5">
                  <Link to="hero" smooth={true}>
                    {t("footer.titleLink")}
                  </Link>
                </li>
                {t("footer.links").map((el, idx) => (
                  <li key={el}>
                    <Link to={sectionsIds[idx]} smooth={true}>
                      {el}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
        <div className="flex items-baseline justify-between">
          <LanguageChange white={true} />
          <p id="contacts">2023 &copy; All Rights Reserved</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

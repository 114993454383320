import { useTranslation } from "react-i18next";
import Icons from "../Icons/Icons";

const AfterFormError = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center w-[225px] mx-auto pb-8">
      <p className="text-[20px] uppercase mb-[10px]">{t("afterForm.error")}</p>
      <Icons name="crossRed" className="w-16 h-16 mb-5 mx-auto" />
      <p>{t("afterForm.later")}</p>
    </div>
  );
};

export default AfterFormError;

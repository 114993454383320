const Container = ({ children, className }) => {
  return (
    <div
      className={
        className
          ? className +
            " w-full px-5 sm:w-[480px] md:w-[768px] md:px-10 xl:w-[1280px] xl:px-[60px] mx-auto"
          : "w-full px-5 sm:w-[480px] md:w-[768px] md:px-10 xl:w-[1280px] xl:px-[60px] mx-auto"
      }
    >
      {children}
    </div>
  );
};

export default Container;

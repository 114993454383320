import { Link } from "react-scroll";
import Icons from "../Icons/Icons";

const BravoMobPageNav = ({ ids, btnAction }) => {
  return (
    <div className=" relative">
      <button onClick={btnAction} className=" absolute top-[10px] right-[10px]">
        <Icons name="cross" className="w-6 h-6" />
      </button>
      <ul className="flex flex-col gap-[10px] pt-[34px] pl-5 pb-8 w-[280px]">
        {ids.map((id) => (
          <li key={id}>
            <Link
              onClick={btnAction}
              to={id}
              smooth={true}
              className="flex gap-3 items-center h-[40px]"
            >
              <span className=" font-bold leading-[1.4] uppercase">{id}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BravoMobPageNav;

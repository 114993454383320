import { Link } from "react-router-dom";
import LanguageChange from "../LanguageChange/LanguageChange";
import { useTranslation } from "react-i18next";
import ContactsLink from "./ContactsLink";

const NavBar = () => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-[60px]">
      <nav>
        <ul className="flex gap-[10px]">
          <li>
            <Link className="p-[10px] text-myRed font-semibold" to="/">
              {t("pageNav.home")}
            </Link>
          </li>
          <li>
            <Link className="p-[10px]" to="/bravo">
              {t("pageNav.bravo")}
            </Link>
          </li>
          <li>
            <ContactsLink />
          </li>
        </ul>
      </nav>
      <LanguageChange />
    </div>
  );
};

export default NavBar;

import AboutUs from "../../components/AboutUs/AboutUs";
import Complexes from "../../components/Complexes/Complexes";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import FormModal from "../../components/FormModal/FormModal";
import Funds from "../../components/Funds/Funds";
import Header from "../../components/Header";
import Hero from "../../components/Hero/Hero";
import IndividualOrders from "../../components/IndividualOrders/IndividualOrders";
import Opportunities from "../../components/Opportunities/Opportunities";
import Projects from "../../components/Projects/Projects";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import Support from "../../components/Support/Support";
import Vodogray from "../../components/Vodogray/Vodogray";
import WhyWe from "../../components/WhyWe/WhyWe";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Vodogray />
      <Opportunities />
      <Complexes />
      <IndividualOrders />
      <Support />
      <Funds />
      <ContactUs />
      <AboutUs />
      <SocialMedia />
      <WhyWe />
      <Projects />
      <FormModal />
      <Footer />
    </>
  );
};

export default Home;

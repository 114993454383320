import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import BravoTabNav from "../BravoTabNav/BravoTabNav";
import { useState } from "react";
import BravoProductsList from "../BravoProductsList/BravoProductsList";
import BravoMobPageMenu from "../BravoMobPageMenu/BravoMobPageMenu";

const BravoMainSection = ({ burgerIsOpen }) => {
  const [activeTab, setActiveTab] = useState("BRAVO - S");
  const isDesc = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const { t } = useTranslation();
  const { breadCrumbs } = t("bravoSection");
  const tabClickAction = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <section className="pb-10 md:pb-[60px] xl:pb-[100px]">
      <Container>
        {!isDesc && !burgerIsOpen && <BravoMobPageMenu />}
        <div className="py-5 xl:pb-10 text-[10px] xl:text-[14px]">
          <Link to="/">{breadCrumbs.link}</Link>
          <span className="text-myGray">{breadCrumbs.text}</span>
        </div>
        {isDesc && (
          <BravoTabNav activeTab={activeTab} tabClickAction={tabClickAction} />
        )}
        <BravoProductsList activeTab={activeTab} />
      </Container>
    </section>
  );
};

export default BravoMainSection;

import { useEffect, useRef, useState } from "react";
import ContactForm from "../ContactForm/ContactForm";
import Container from "../Container/Container";
import { useModal } from "../Context/ModalContext";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Icons from "../Icons/Icons";
import AfterForm from "../AfterForm/AfterForm";
import AfterFormError from "../AfterForm/AfterFormError";

const FormModal = () => {
  const { isOpen, setClose } = useModal();
  const [done, setIsDone] = useState(false);
  const modalRef = useRef(null);
  useEffect(() => {
    isOpen ? disableBodyScroll(modalRef) : clearAllBodyScrollLocks();
  }, [isOpen]);
  const closeBtnAction = () => {
    setClose();
    setIsDone(false);
  };
  return (
    isOpen && (
      <div className="fixed left-0 top-0 right-0 bottom-0 z-[1001] flex items-center justify-center bg-myBlack bg-opacity-80 blur-[25px] filter-none">
        <Container className="flex items-center justify-center">
          <div
            className="w-full md:max-w-[504px] p-5 pb-10 bg-myWhite rounded-[15px]"
            ref={modalRef}
          >
            <button
              onClick={closeBtnAction}
              type="button"
              aria-label="modal close"
              className="block ml-auto mb-5"
            >
              <Icons name="cross" className="w-8 h-8" />
            </button>
            {!done && <ContactForm setIsDone={setIsDone} />}
            {done === "done" && <AfterForm />}
            {done === "fail" && <AfterFormError />}
          </div>
        </Container>
      </div>
    )
  );
};

export default FormModal;

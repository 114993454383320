import Container from "./Container/Container";
import Icons from "./Icons/Icons";
import { useMediaQuery } from "react-responsive";
import MenuButton from "./Buttons/MenuButton";
import { useState } from "react";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import NavBar from "./NavBar/NavBar";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDecktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const menuButtonAction = () => {
    setIsMenuOpen(true);
  };
  const menuCloseBtnAction = () => {
    setIsMenuOpen(false);
  };
  return (
    <header
      className={
        "text-[18px] text-myWhite bg-transparent leading-[1.2] absolute w-full top-5"
      }
    >
      <Container className="flex flex-row justify-between items-center relative">
        <div>
          <Icons name="logo" className="w-[100px] xl:w-[122px]" />
        </div>

        {isDecktop ? (
          <NavBar />
        ) : (
          <>
            <BurgerMenu
              menuCloseBtnAction={menuCloseBtnAction}
              isMenuOpen={isMenuOpen}
            />
            <MenuButton clickAction={menuButtonAction} />
          </>
        )}
      </Container>
    </header>
  );
};
export default Header;

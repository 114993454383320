import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import css from "./Support.module.css";

const Support = () => {
  const { t } = useTranslation();
  return (
    <section
      className={`${css.section} pt-[105px] md:pt-[157px] pb-[60px] md:pb-[66px] xl:pt-[73px] xl:pb-[66px] text-myWhite text-center xl:text-start relative max-w-[1600px] mx-auto`}
    >
      <Container>
        <h2 className="section-title mb-5">{t("support.title")}</h2>
        <h3 className="section-subtitle mb-10 md:w-[320px] md:mx-auto xl:w-full">
          {t("support.subtitle")}
        </h3>
        <p className="mb-[80px] md:mb-[60px] xl:mb-10 xl:w-[320px] w-[270px] mx-auto xl:ml-0">
          {t("support.textBlock")}
        </p>
        <a
          href="https://send.monobank.ua/jar/2Q2Sfct6hX"
          target="_blank"
          rel="noreferrer noopener"
          className="w-full max-w-[320px] py-[15px] px-[60px] bg-myRed rounded-[15px] text-[16px] text-center xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300"
        >
          {t("support.button")}{" "}
        </a>
        <p className="text-[12px] text-myGray absolute left-5 bottom-[10px] md:left-10 xl:right-[60px] xl:bottom-[25px] xl:left-auto">
          {t("support.bottomText")}
        </p>
      </Container>
    </section>
  );
};

export default Support;

import { useTranslation } from "react-i18next";
import css from "./Hero.module.css";
import Container from "../Container/Container";
import toCapitalize from "../../utils/toCapitalize";
import Icons from "../Icons/Icons";
import ConsultationBtn from "../Buttons/ConsultationBtn";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <section
      id="hero"
      className={
        css.heroSection +
        " pt-[188px] md:pt-[148px] xl:pt-[200px] pb-[96px] md:pb-[63px] xl:pb-[135px] max-w-[1600px] mx-auto"
      }
    >
      <Container>
        <h1 className="text-[35px] xl:text-[55px] text-myWhite font-bold mb-20 md:mb-5 xl:mb-10 text-center md:text-start tracking-[0.35px] xl:tracking-[0.55px] uppercase">
          {t("hero.title")}
        </h1>
        <div className="mb-[60px] md:mb-10 xl:mb-[60px] md:ml-[378px] xl:ml-[595px]">
          <h2 className="text-[30px] xl:text-[40px] text-myWhite font-bold italic items-center md:items-start flex flex-col gap-4 md:gap-0 xl:gap-[10px] mb-4 md:mb-[10px] xl:mb-5 uppercase">
            <span>{t("hero.subtitle")}</span>
            <span className="text-myRed">{t("hero.subtitleAccent")}</span>
          </h2>
          <Icons
            name="chevron"
            className="w-10 xl:w-12 h-10 xl:h-12 stroke-myRed mx-auto md:mx-0"
          />
        </div>
        <div className="text-center md:text-start md:flex md:justify-between">
          <p className="text-myWhite text-[20px] max-w-[315px] mb-20 md:mb-0 mx-auto md:mx-0 uppercase">
            {toCapitalize(t("hero.textBlock"))}
          </p>
          <ConsultationBtn className="max-w-[320px] md:max-w-[225px] xl:max-w-[320px] xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300" />
        </div>
      </Container>
    </section>
  );
};
export default Hero;

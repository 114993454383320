import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import OpportunitiesCards from "../OpportunitiesCards/OpportunitiesCards";
import Icons from "../Icons/Icons";

const Opportunities = () => {
  const { t } = useTranslation();
  const { title, subtitle, bottomText, timeCards, connection } =
    t("opportunities");
  return (
    <section className="pt-[80px] md:pt-[100px]">
      <Container>
        <div className="mb-10 md:flex md:justify-between">
          <h2 className="section-title mb-5">{title}</h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[475px]">
            <span className="section-subtitle-accent">
              {subtitle.accent1 + " "}
            </span>
            {subtitle.regular1}
            <span className="section-subtitle-accent">
              {" " + subtitle.accent2 + " "}
            </span>
            {subtitle.regular2}
            <span className="section-subtitle-accent">
              {" " + subtitle.accent3}
            </span>
          </h3>
        </div>
        <div className="xl:flex xl:justify-between">
          <div className="relative">
            <OpportunitiesCards />
            <p className="pt-[10px] text-[12px] md:w-[calc(50%-5px)]">
              {bottomText}
            </p>
            <div className="py-10 flex flex-col items-center xl:absolute xl:flex-row xl:top-[296.5px] xl:left-[375px] xl:py-0">
              <p className="uppercase text-[20px] font-semibold">
                {connection}
              </p>
              <Icons
                name="chevron"
                className="w-12 h-12 stroke-myBlack xl:rotate-[270deg]"
              />
            </div>
          </div>
          <ul className="flex flex-col gap-[10px] md:max-xl:flex-row md:max-xl:flex-wrap xl:w-[475px]">
            {timeCards.map((el, idx) => (
              <li
                className="bg-myLightGray flex flex-row py-5 px-[30px] rounded-[15px] gap-[15px] md:max-xl:w-[calc(50%-5px)] xl:py-[32px] xl:px"
                key={idx}
              >
                <p className="flex w-[calc(50%-7.5px)] text-[20px] font-semibold uppercase ">
                  {el.left}
                </p>
                <div className="flex flex-row gap-[10px]">
                  <Icons name="clock" className="w-6 h-6 stroke-myBlack" />
                  <p className="text-[16px]">{el.right}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default Opportunities;

import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const emailRegExp = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;

const phoneRegExp = /^\+[1-9]\d{11}$/;

export const SectionForm = ({ setIsDone }) => {
  const { t } = useTranslation();
  const form = useRef();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);

  const {
    nameText,
    phoneText,
    namePH,
    emailText,
    submitText,
    nameErrorText,
    emailErrorText,
    phoneErrorText,
  } = t("form");
  const sendEmail = (e) => {
    e.preventDefault();
    if (!emailRegExp.test(email) || !phoneRegExp.test(phone) || !userName) {
      setEmailValid(emailRegExp.test(email));
      setPhoneValid(phoneRegExp.test(phone));
      setUserNameValid(userName !== "");
      return;
    }
    e.target[3].disabled = true;
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          setIsDone("done");
        },
        () => {
          setIsDone("fail");
        }
      );
  };

  return (
    <form
      className="flex flex-col"
      ref={form}
      onSubmit={sendEmail}
      autoComplete="on"
      noValidate
    >
      <label className="flex flex-col">
        <span>{nameText}</span>
        <input
          className={`px-[25px] py-[10px] mt-1 rounded-[10px] border-[1px] bg-transparent ${
            userNameValid !== true ? "border-myRed" : "border-myWhite"
          }`}
          placeholder={namePH}
          type="text"
          name="user_name"
          onBlur={(e) => {
            setUserNameValid(e.target.value.trim() === "" ? false : true);
          }}
          onChange={(e) => {
            setUserName(e.target.value.trim());
          }}
        />
        <span className="block h-[14px]">
          {userNameValid !== true && (
            <span className="text-myRed text-[10px] block">
              {nameErrorText}
            </span>
          )}
        </span>
      </label>
      <label className="flex flex-col">
        <span>{phoneText}</span>
        <input
          className={`px-[25px] py-[10px] mt-1 rounded-[10px] border-[1px] bg-transparent ${
            userNameValid !== true ? "border-myRed" : "border-myWhite"
          }`}
          onChange={(e) => {
            setPhone(e.target.value.trim());
          }}
          onBlur={(e) => {
            setPhoneValid(phoneRegExp.test(e.target.value.trim()));
          }}
          placeholder="+380"
          type="text"
          name="user_phone"
        />
        <span className="block h-[14px]">
          {phoneValid !== true && (
            <span className="text-myRed text-[10px] block">
              {phoneErrorText}
            </span>
          )}
        </span>
      </label>
      <label className="flex flex-col">
        <span>{emailText}</span>
        <input
          className={`px-[25px] py-[10px] mt-1 rounded-[10px] border-[1px] bg-transparent ${
            userNameValid !== true ? "border-myRed" : "border-myWhite"
          }`}
          onChange={(e) => {
            setEmail(e.target.value.trim().toLowerCase());
          }}
          onBlur={(e) => {
            setEmailValid(
              emailRegExp.test(e.target.value.trim().toLowerCase())
            );
          }}
          placeholder="Welding2626@gmail.com"
          type="email"
          name="user_email"
        />
        <span className="block h-[14px]">
          {emailValid !== true && (
            <span className="text-myRed text-[10px] block">
              {emailErrorText}
            </span>
          )}
        </span>
      </label>

      <input
        className="px-[25px] py-[14px] mt-5 text-[18px] rounded-[15px] text-myBlack bg-myWhite disabled:bg-myGray cursor-pointer"
        type="submit"
        value={submitText}
      />
    </form>
  );
};

export default SectionForm;

const AdaptiveImg = ({ imgName, full }) => {
  return (
    <picture>
      <source
        media="(min-width: 1280px)"
        srcSet={`/images/${imgName}-d.jpg 1x,
                /images/${imgName}-d2x.jpg 2x`}
        type="image/jpg"
      />
      <source
        media="(min-width: 768px)"
        srcSet={`/images/${imgName}-t.jpg 1x,
                              /images/${imgName}-t2x.jpg 2x`}
        type="image/jpg"
      />
      <source
        media="(max-width: 767.9px)"
        srcSet={`/images/${imgName}-m.jpg 1x,
                              /images/${imgName}-m2x.jpg 2x`}
        type="image/jpg"
      />
      <img
        className={full && "w-full"}
        loading="lazy"
        src={`/images/${imgName}-m.jpg`}
        alt={imgName}
      />
    </picture>
  );
};

export default AdaptiveImg;

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Slider = ({ children }) => {
  return (
    <div>
      <Swiper
        loop={true}
        effect="fade"
        modules={[EffectFade, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {children.map((el, idx) => {
          return <SwiperSlide key={idx}>{el}</SwiperSlide>;
        })}
      </Swiper>
    </div>
  );
};
export default Slider;

import { Link } from "react-router-dom";
import Icons from "../Icons/Icons";

const LogoLink = () => {
  return (
    <div className="w-[100px] xl:w-[122px] z-30">
      <Link className="w-[100px] xl:w-[122px] z-40" to="/">
        <Icons className="w-[100px] xl:w-[122px] z-50" name="logo" />
      </Link>
    </div>
  );
};

export default LogoLink;

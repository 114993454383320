const SpecList = ({ spec, gap }) => {
  return (
    <ul className="flex flex-col gap-4 xl:gap-5">
      {spec.map((item, idx) => (
        <li key={idx}>
          <p className="flex flex-col gap-2 xl:flex-row xl:gap-0">
            <span className=" font-bold uppercase xl:w-[234px] shrink-0">
              {item.name}
            </span>
            <span className={`flex flex-col ${gap ? "gap-2" : ""}`}>
              <span>{item.desc}</span>
              {item.desc2 && <span>{item.desc2}</span>}
            </span>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default SpecList;

import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";

const WhyWe = () => {
  const { t } = useTranslation();
  const { title, subtitle, cards } = t("whyWe");
  return (
    <section className="pt-[60px] md:pt-[80px] xl:pt-[100px]">
      <Container>
        <div className="flex flex-col gap-5 mb-10 xl:mb-[60px] md:flex-row md:justify-between md:gap-0">
          <h2 className="section-title text-myBlack md:max-w-[calc(50%-5px)]">
            {title}
          </h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px]">
            <span className="section-subtitle-accent">{subtitle.accent1}</span>
            {subtitle.regular1}
            <span className="section-subtitle-accent">{subtitle.accent2}</span>
            {subtitle.regular2}
          </h3>
        </div>
        <ul className="flex flex-col gap-[10px] xl:flex-row xl:gap-5">
          {cards.map((el, idx) => (
            <li
              key={idx}
              className="p-5 md:p-10 xl:px-5 rounded-[15px] bg-myLightGray flex gap-[10px] md:gap-[30px] items-start xl:w-[calc(100%/3-40px/3)]"
            >
              <Icons name={el.icon} className="w-[70px] shrink-0" />
              <div className="md:flex justify-between flex-grow xl:flex-col">
                <h4 className="uppercase font-bold mb-5">{el.title}</h4>
                <p className="md:w-[260px] xl:w-auto">{el.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};
export default WhyWe;

import { createContext, useContext, useState } from "react";

const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const setOpen = () => {
    setIsOpen(true);
  };
  const setClose = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isOpen, setClose, setOpen }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

import { useTranslation } from "react-i18next";
import Icons from "../Icons/Icons";

const ContactsBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="text-[18px] flex flex-col">
      <p className="section-title mb-10">{t("footer.contacts")}</p>
      <a className="mb-5" href="tel:+3800960382626">
        +380 (096) 038-26-26
      </a>
      <a className="mb-5" href="mailto:Welding2626@gmail.com">
        Welding2626@gmail.com
      </a>
      <a
        className="flex w-[125px] justify-between mb-10 mob:mx-auto"
        href="https://wa.me/+380970382626"
        target="_blank"
        rel="noopener noreferrer"
      >
        WhatsApp <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
      </a>
      <p className="section-subtitle mb-10">{t("footer.socialMedia")}</p>
      <a
        className="flex w-[125px] justify-between mb-5"
        href="https://instagram.com/welding_territory?igshid=MzRlODBiNWFlZA=="
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
      </a>
      <a
        className="flex w-[125px] justify-between mb-5"
        href="https://www.tiktok.com/@26krava?_t=8ehSDzUPDGw&_r=1"
        target="_blank"
        rel="noreferrer noopener"
      >
        Tik Tok <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
      </a>
      <a
        className="flex w-[125px] justify-between"
        href="https://youtube.com/@welding_territory"
        target="_blank"
        rel="noopener noreferrer"
      >
        YouTube <Icons name="arrow" className="w-6 h-6 stroke-myRed" />
      </a>
    </div>
  );
};

export default ContactsBlock;

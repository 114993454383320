const ComplexesMenu = ({ btnAction, activeComplex }) => {
  return (
    <div className="flex justify-between items-start mb-10">
      <button
        onClick={btnAction}
        name="vodohray1"
        className={`section-title ${
          activeComplex === "vodohray1" ? "text-myRed" : "text-myGray"
        }`}
        type="button"
      >
        VODOHRAY 1
      </button>
      <button
        onClick={btnAction}
        name="vodohray1eco"
        className={`section-title flex flex-col ${
          activeComplex === "vodohray1eco" ? "text-myRed" : "text-myGray"
        }`}
        type="button"
      >
        <span>VODOHRAY 1</span>
        <span className="text-[14px]">ECONOM</span>
      </button>
      <button
        onClick={btnAction}
        name="vodohray2"
        className={`section-title ${
          activeComplex === "vodohray2" ? "text-myRed" : "text-myGray"
        }`}
        type="button"
      >
        VODOHRAY 2
      </button>
      <button
        onClick={btnAction}
        name="vodohray2eco"
        className={`section-title flex flex-col ${
          activeComplex === "vodohray2eco" ? "text-myRed" : "text-myGray"
        }`}
        type="button"
      >
        <span>VODOHRAY 2</span>
        <span className="text-[14px]">ECONOM</span>
      </button>
    </div>
  );
};

export default ComplexesMenu;

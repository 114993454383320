import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

const ContactsLink = ({ clickAction }) => {
  const { t } = useTranslation();
  const handleClick = () => {
    if (clickAction) {
      clickAction();
    }
  };
  return (
    <Link
      onClick={handleClick}
      className="p-[10px] cursor-pointer"
      to="contacts"
      smooth={true}
    >
      {t("footer.contacts")}
    </Link>
  );
};

export default ContactsLink;

import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";

const SocialMedia = () => {
  const { t } = useTranslation();
  const { title, subtitle, textBlock } = t("socialMedia");
  return (
    <section className="pt-[60px] md:pt-[80px] xl:pt-[100px]">
      <Container>
        <div className="flex flex-col gap-5 mb-[10px] xl:mb-[0px] md:flex-row md:justify-between md:gap-0">
          <h2 className="section-title text-myBlack md:max-w-[calc(50%-5px)]">
            {title}
          </h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px]">
            {subtitle.regular1}
            <span className="section-subtitle-accent">{subtitle.accent1}</span>
            {subtitle.regular2}
            <span className="section-subtitle-accent">{subtitle.accent2}</span>
          </h3>
        </div>
        <p className="mb-10 md:w-[calc(50%-5px)] xl:w-[472px] xl:mb-[60px] md:ml-auto">
          {textBlock}
        </p>
        <ul className="text-[20px] uppercase flex flex-col gap-[10px] items-center md:flex-row xl:gap-5">
          <li className="group rounded-[15px] border-solid border-[1px] max-w-[320px] h-[55px] w-full xl:max-w-none xl:h-[82px] xl:hover:bg-myBlack xl:hover:text-myWhite transition-colors ease-in-out duration-300">
            <a
              className="w-full h-full flex gap-[10px] items-center justify-center"
              href="https://youtube.com/@welding_territory"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons
                name="youtube"
                className="w-[29px] h-6 fill-myRed group-xl:hover:fill-myWhite transition-colors ease-in-out duration-300"
              />
              {"youtube"}
            </a>
          </li>
          <li className="group rounded-[15px] border-solid border-[1px] max-w-[320px] h-[55px] w-full xl:max-w-none xl:h-[82px] xl:hover:bg-myBlack xl:hover:text-myWhite transition-colors ease-in-out duration-300">
            <a
              className="w-full h-full flex gap-[10px] items-center justify-center"
              href="https://instagram.com/welding_territory?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons
                name="instagram"
                className="w-[24px] fill-myRed group-xl:hover:fill-myWhite transition-colors ease-in-out duration-300"
              />
              {"Instagram"}
            </a>
          </li>
          <li className="group rounded-[15px] border-solid border-[1px] max-w-[320px] h-[55px] w-full xl:max-w-none xl:h-[82px] xl:hover:bg-myBlack xl:hover:text-myWhite transition-colors ease-in-out duration-300">
            <a
              className="w-full h-full flex gap-[10px] items-center justify-center"
              href="https://www.tiktok.com/@26krava?_t=8ehSDzUPDGw&_r=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons
                name="tiktok"
                className="w-[20px] fill-myRed group-xl:hover:fill-myWhite transition-colors ease-in-out duration-300"
                part="fill-myWhite group-xl:hover:fill-myBlack transition-colors ease-in-out duration-300"
              />
              {"tik tok"}
            </a>
          </li>
        </ul>
      </Container>
    </section>
  );
};
export default SocialMedia;

import { Link } from "react-scroll";
import Container from "../Container/Container";
import Icons from "../Icons/Icons";
import LanguageChange from "../LanguageChange/LanguageChange";
import LogoLink from "./LogoLink";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ContactsBlock from "./ContactsBlock";
import LinkHome from "./LinkHome";

const BravoFooter = () => {
  const { t } = useTranslation();
  const isDecktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  return (
    <footer className="bg-myBlack text-myWhite py-5 xl:py-[60px]">
      <Container>
        <div className="flex flex-row justify-between items-center mb-10 xl:mb-[60px] relative">
          <LogoLink />
          <Link to="bravoHeader" smooth={true}>
            <Icons
              name="chevron"
              className="w-12 h-12 xl:w-[58px] xl:h-[58px] stroke-myWhite rotate-180"
            />
          </Link>
        </div>

        <div className="mb-10 xl:mb-[60px] xl:flex justify-between">
          {isDecktop && <ContactsBlock />}
          <div className="text-center">
            <p className="section-title w-[215px] xl:w-auto mx-auto mb-5 xl:mb-10">
              {t("support.title")}
            </p>
            <p className="mb-10 xl:mb-[60px] max-w-[312px] mx-auto">
              {t("support.textBlock")}
            </p>
            <a
              href="https://send.monobank.ua/jar/2Q2Sfct6hX"
              target="_blank"
              rel="noreferrer noopener"
              className="w-full max-w-[320px] py-[15px] px-[60px] bg-myRed rounded-[15px] text-[16px] text-center xl:hover:bg-myWhite xl:hover:text-myBlack transition-colors ease-in-out duration-300"
            >
              {t("support.button")}{" "}
            </a>
          </div>
          {isDecktop && (
            <div className="section-title">
              <LinkHome />
            </div>
          )}
        </div>

        <div className="flex items-baseline justify-between">
          <LanguageChange white={true} />
          <p>2023 &copy; All Rights Reserved</p>
        </div>
      </Container>
    </footer>
  );
};

export default BravoFooter;

import { useState } from "react";
import BravoFooter from "../../components/BravoFooter/BravoFooter";
import BravoHeader from "../../components/BravoHeader/BravoHeader";
import BravoMainSection from "../../components/BravoMainSection/BravoMainSection";
import FormModal from "../../components/FormModal/FormModal";

const Bravo = () => {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  return (
    <>
      <BravoHeader setBurgerIsOpen={setBurgerIsOpen} />
      <BravoMainSection burgerIsOpen={burgerIsOpen} />
      <FormModal />
      <BravoFooter />
    </>
  );
};

export default Bravo;

import { useTranslation } from "react-i18next";
import Icons from "../Icons/Icons";

const OpportunitiesCards = () => {
  const { t } = useTranslation();
  const { cards } = t("opportunities");
  return (
    <ul className="text-myWhite flex flex-col gap-[10px] text-[16px] md:flex-row md:flex-wrap xl:w-[570px]">
      <li className="bg-myBlack py-5 px-10 rounded-[15px] md:w-[calc(50%-5px)] xl:w-[275px] xl:py-10">
        <p className="font-bold pb-5 uppercase text-[14px]">{cards[0].title}</p>
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="shield" className="stroke-myWhite w-6 h-6" />
            <p>{cards[0].text1}</p>
          </div>
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="clock" className="stroke-myWhite w-6 h-6" />
            <p>{cards[0].text2}</p>
          </div>
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="droplet" className="stroke-myWhite w-6 h-6" />
            <p>{cards[0].text3}</p>
          </div>
        </div>
      </li>
      <li className="bg-myBlack py-5 px-10 rounded-[15px] md:w-[calc(50%-5px)] xl:w-[275px] xl:py-10 order-last md:order-none">
        <p className="font-bold pb-5 uppercase text-[14px]">{cards[1].title}</p>
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="users" className="stroke-myWhite w-6 h-6" />
            <p>{cards[1].text1}</p>
          </div>
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="clock" className="stroke-myWhite w-6 h-6" />
            <p>{cards[1].text2}</p>
          </div>
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="droplet" className="stroke-myWhite w-6 h-6" />
            <p>{cards[1].text3}</p>
          </div>
        </div>
      </li>
      <li className="bg-myBlack py-5 px-10 rounded-[15px] md:w-[calc(50%-5px)] xl:w-[275px] xl:py-10">
        <p className="font-bold pb-5 uppercase text-[14px]">{cards[2].title}</p>
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="clock" className="stroke-myWhite w-6 h-6" />
            <p>{cards[2].text1}</p>
          </div>
          <div className="flex flex-row gap-[23px] items-center">
            <Icons name="refresh" className="stroke-myWhite w-6 h-6" />
            <p>{cards[2].text2}</p>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default OpportunitiesCards;

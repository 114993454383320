import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import fund01Src from "../../images/fund-01.png";
import fund01x2Src from "../../images/fund-01@2x.png";
import fund02Src from "../../images/fund-02.png";
import fund02x2Src from "../../images/fund-02@2x.png";
import Icons from "../Icons/Icons";

const Funds = () => {
  const { t, i18n } = useTranslation();
  const { title, subtitle, fund2, fund3 } = t("funds");
  return (
    <section id="funds" className="py-[80px] md:py-[100px]">
      <Container>
        <div className="flex flex-col gap-5 mb-10 xl:mb-[60px] md:flex-row justify-between">
          <h2
            className={`section-title text-myRed ${
              i18n.language === "ua"
                ? "max-w-[200px] xl:max-w-[270px]"
                : "max-w-[215px] xl:max-w-[270px]"
            }`}
          >
            {title}
          </h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px] xl:pr-[100px]">
            <span className="section-subtitle-accent">{subtitle.accent1}</span>
            {subtitle.regular1}
            <span className="section-subtitle-accent">{subtitle.accent2}</span>
            {subtitle.regular2}
            <span className="section-subtitle-accent">{subtitle.accent3}</span>
            {subtitle.regular3}
          </h3>
        </div>
        <ul className="flex flex-row flex-wrap gap-[10px] font-bold uppercase md:text-[18px]">
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://zadnipro.dp.ua/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="zadnipro fund link"
            >
              <img
                className="w-[120px] md:w-[200px]"
                srcSet={`${fund01Src} 1x, ${fund01x2Src} 2x`}
                src={fund01Src}
                alt="zadnipro fund logo"
              />
            </a>
          </li>
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://genesis-for-ukraine.com/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="genesis fund link"
            >
              <Icons name="genesis" className="w-[100px] md:w-[130px]" />
            </a>
          </li>
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://dignitas.fund/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons name="dignitas" className="w-[100px] md:w-[130px]" />
            </a>
          </li>
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://www.razomforukraine.org/ua/homeua/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="razom for ukraine fund link"
            >
              <img
                className="w-[100px] md:w-[150px]"
                srcSet={`${fund02Src} 1x, ${fund02x2Src} 2x`}
                src={fund02Src}
                alt="razom for ukraine logo"
              />
            </a>
          </li>
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://youcontrol.com.ua/catalog/company_details/44770290/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="w-[135px] md:w-full text-center">{fund2}</span>
            </a>
          </li>
          <li className="w-[calc(50%-5px)] h-[90px] md:h-[82px] rounded-[10px] border-[1px] border-solid border-myBlack xl:w-[calc(100%/3-40px/3)] xl:hover:animate-scale-fast">
            <a
              className="w-full h-full flex items-center justify-center"
              href="https://opendatabot.ua/c/44729200"
              target="_blank"
              rel="noreferrer noopener"
            >
              {fund3}
            </a>
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default Funds;

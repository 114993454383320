import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import { useState } from "react";
import AdaptiveImg from "../AdaptiveImg/AdaptiveImg";

const Projects = () => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const showMoreBtnAction = () => {
    setShowAll(true);
  };
  return (
    <section id="projects" className="py-[80px] md:py-[100px]">
      <Container>
        <div className="mb-10 md:mb-[60px]">
          <h2 className="section-title text-myBlack max-w-[315px] md:w-full">
            {t("projects")}
          </h2>
        </div>
        <ul className="flex flex-col gap-[10px] xl:gap-5 mb-10">
          <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
            <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
              <AdaptiveImg imgName="bravo-1-big" />
            </div>
            <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
              <AdaptiveImg imgName="bravo-1-sm-1" />
            </div>
            <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
              <AdaptiveImg imgName="bravo-1-sm-2" />
            </div>
          </li>
          <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
            <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
              <AdaptiveImg imgName="bravo-2-big" />
            </div>
            <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
              <AdaptiveImg imgName="bravo-2-sm-1" />
            </div>
            <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
              <AdaptiveImg imgName="bravo-2-sm-2" />
            </div>
          </li>
          {showAll && (
            <>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="box" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="divan" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="charge" />
                </div>
              </li>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="ring" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="tualet" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="pole" />
                </div>
              </li>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="chargepoint" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="backet" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="open" />
                </div>
              </li>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="chargeEl" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="gym" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="balkkony" />
                </div>
              </li>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="ground" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="garage" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="dore" />
                </div>
              </li>
              <li className="flex flex-wrap gap-[10px] xl:gap-5 md:even:flex-row-reverse">
                <div className="w-full md:w-[calc(50%-5px)] xl:w-[calc(50%-10px)]">
                  <AdaptiveImg imgName="sportComplex" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="garden" />
                </div>
                <div className="w-[calc(50%-5px)] md:w-[calc(25%-7.5px)] xl:w-[calc(25%-15px)]">
                  <AdaptiveImg imgName="tube" />
                </div>
              </li>
            </>
          )}
        </ul>
        {!showAll && (
          <button
            className="block w-full max-w-[320px] mx-auto py-[15px] px-[60px] text-[16px] rounded-[15px] border-solid border-[1px]"
            onClick={showMoreBtnAction}
          >
            {t("buttons.showMore")}
          </button>
        )}
      </Container>
    </section>
  );
};
export default Projects;

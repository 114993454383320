import LanguageChange from "../LanguageChange/LanguageChange";
import Icons from "../Icons/Icons";
import { useEffect, useRef } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Container from "../Container/Container";
import BravoBurgerNav from "../BravoBurgerNav/BravoBurgerNav";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactsLink from "../NavBar/ContactsLink";

const BurgerMenu = ({ menuCloseBtnAction, isMenuOpen, bravo }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  useEffect(() => {
    isMenuOpen ? disableBodyScroll(menuRef) : clearAllBodyScrollLocks();
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isMenuOpen]);

  return (
    <div
      className={`${
        isMenuOpen ? "block" : "hidden"
      } fixed left-0 top-0 right-0 bottom-0 z-10 bg-myBlack bg-opacity-80 blur-[25px] filter-none`}
    >
      <Container className="pt-10">
        <div
          ref={menuRef}
          className={`relative ml-auto bg-myWhite text-myBlack rounded-[10px] w-full p-10 pt-[70px] md:w-[360px]`}
        >
          <button
            onClick={menuCloseBtnAction}
            type="button"
            className="absolute top-5 right-5"
          >
            <Icons name="cross" className="w-8 h-8" />
          </button>
          {!bravo && (
            <nav className="mb-[100px]">
              <ul className="flex flex-col gap-[10px]">
                <li>
                  <Link className="p-[10px] text-myRed font-semibold" to="/">
                    {t("pageNav.home")}
                  </Link>
                </li>
                <li>
                  <Link className="p-[10px]" to="/bravo">
                    {t("pageNav.bravo")}
                  </Link>
                </li>
                <li>
                  <ContactsLink clickAction={menuCloseBtnAction} />
                </li>
              </ul>
            </nav>
          )}
          {bravo && <BravoBurgerNav />}
          <div className="px-[10px]">
            <LanguageChange />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BurgerMenu;

import { useEffect, useRef, useState } from "react";
import Icons from "../Icons/Icons";
import { useTranslation } from "react-i18next";
import BravoMobPageNav from "./BravoMobPageNav";

const BravoMobPageMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const tabNames = t("bravoSection.tabNav");
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const openClickAction = () => {
    setIsOpen(true);
  };
  const closeClickAction = () => {
    setIsOpen(false);
  };
  return (
    <div
      ref={wrapperRef}
      className="fixed min-w-[40px] min-h-[40px] top-[200px] mobOnly:right-5 right-[calc((100vw-480px)/2+20px)] md:right-[calc((100vw-768px)/2+40px)] bg-myLightGray border-2 border-myBlack rounded-lg z-[1000]"
    >
      {!isOpen && (
        <div
          onClick={openClickAction}
          className="flex items-center justify-center p-2"
        >
          <Icons name="more" className="w-6 h-6" />
        </div>
      )}

      {isOpen && (
        <BravoMobPageNav
          btnAction={closeClickAction}
          ids={tabNames.map((item) => item.name)}
        />
      )}
    </div>
  );
};

export default BravoMobPageMenu;

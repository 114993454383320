import { useState } from "react";
import Icons from "../Icons/Icons";
import { useMediaQuery } from "react-responsive";

const BravoAccordion = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const btnAction = () => {
    setIsOpen((prev) => !prev);
  };
  const notMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  return (
    <div className="p-5 xl:p-10 grow rounded-[8px] xl:rounded-[15px] bg-myLightGray">
      <div
        className={`${
          notMobile || isOpen
            ? "border-b-[#DFDDDD] border-b pb-5 xl:pb-[10px] mb-5 xl:mb-10"
            : ""
        } uppercase text-[16px] xl:text-[20px] font-semibold flex justify-between items-center`}
      >
        <h5>{title}</h5>
        {!notMobile && (
          <button
            onClick={btnAction}
            type="button"
            aria-label="toggle accordion"
          >
            <Icons
              name={isOpen ? "cross" : "arrow"}
              className="w-8 h-8 stroke-myBlack"
            />
          </button>
        )}
      </div>
      {(notMobile || isOpen) && children}
    </div>
  );
};

export default BravoAccordion;

import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import mUrl from "../../images/vodogray-m.jpg";
import mUrlx2 from "../../images/vodogray-m@2x.jpg";
import tUrl from "../../images/vodogray-t.jpg";
import tUrlx2 from "../../images/vodogray-t@2x.jpg";
import dUrl from "../../images/vodogray-d.jpg";
import dUrlx2 from "../../images/vodogray-d@2x.jpg";
import Icons from "../Icons/Icons";
import { useInView } from "react-intersection-observer";
import { Suspense, lazy } from "react";
import AdaptiveImg from "../AdaptiveImg/AdaptiveImg";

const VodograyVideo = lazy(() => import("./VodograyVideo"));

const Vodogray = () => {
  const { t } = useTranslation();
  const { title, subtitle, descriptions, bottomText, bottomSubtitle } =
    t("vodogray");
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  return (
    <section className="pt-[80px] md:pt-[100px]">
      <Container>
        <div className="flex flex-col gap-5 mb-10 xl:mb-[60px] md:flex-row justify-between md:gap-0">
          <h2 className="section-title text-myRed md:w-[300px]">{title}</h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px]">
            {subtitle.regular1}
            <span className="section-subtitle-accent">{subtitle.accent1}</span>
            {subtitle.regular2}
            <span className="section-subtitle-accent">{subtitle.accent2}</span>
          </h3>
        </div>
        <div className="flex flex-col gap-10 md:flex-row md:gap-[10px] mb-10 md:mb-5 xl:mb-10">
          <div className="md:w-[calc(50%-5px)] xl:w-[670px] md:h-[347px] xl:h-[470px] shrink-0">
            <picture>
              <source
                media="(min-width: 1280px)"
                srcSet={`${dUrl} 1x,
                              ${dUrlx2} 2x`}
                type="image/jpg"
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${tUrl} 1x,
                              ${tUrlx2} 2x`}
                type="image/jpg"
              />
              <source
                media="(max-width: 767.9px)"
                srcSet={`${mUrl} 1x,
                              ${mUrlx2} 2x`}
                type="image/jpg"
              />
              <img loading="lazy" src={mUrl} alt="vodohray" />
            </picture>
          </div>
          <ul className="flex flex-col gap-[10px] mb-10 xl:w-[472px] xl:shrink-0 md:mb-0 md:gap-0 md:h-[347px] xl:h-[470px] md:justify-between">
            {descriptions.map((el, idx) => (
              <li
                className="p-5 rounded-[10px] xl:rounded-[15px] bg-myLightGray flex gap-[30px] items-center xl:pl-7 xl:py-[21px] xl:pr-[106px] md:px-5 md:py-[10px] md:gap-5 mob:min-h-[108px]"
                key={idx}
              >
                <Icons
                  name="check"
                  className="w-10 h-10 md:w-[28px] md:h-[28px] xl:w-10 xl:h-10 shrink-0"
                />
                <p className="text-[16px] md:text-[14px] xl:text-[16px]">
                  {el}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <p className="section-subtitle mb-5 md:w-[calc(50%-5px)]">
          <span className="section-subtitle-accent">
            {bottomSubtitle.accent1}
          </span>
          {bottomSubtitle.regular1}
          <span className="section-subtitle-accent">
            {bottomSubtitle.accent2}
          </span>
        </p>
        <p className="max-w-[327px] xl:max-w-[366px] mb-10 md:mb-5 xl:mb-10">
          {bottomText}
        </p>
        <div className="w-full h-[248px] xl:h-[653px] md:h-[387px]" ref={ref}>
          {inView ? (
            <Suspense>
              <VodograyVideo />
            </Suspense>
          ) : (
            <AdaptiveImg imgName="poster" />
          )}
        </div>
      </Container>
    </section>
  );
};

export default Vodogray;

import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import { useMediaQuery } from "react-responsive";
import mapUrl from "../../images/map.svg";
const AboutUs = () => {
  const notMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const { t } = useTranslation();
  const { title, subtitle, stats } = t("aboutUs");
  return (
    <section id="about us" className="pt-[80px] md:pt-[100px]">
      <Container>
        <div className="flex flex-col gap-5 mb-10 xl:mb-[60px] md:flex-row justify-between">
          <h2 className="section-title text-myRed">{title}</h2>
          <h3 className="section-subtitle md:w-[calc(50%-5px)] xl:w-[472px] xl:pr-[50px]">
            {subtitle.regular1}
            <span className="section-subtitle-accent">{subtitle.accent1}</span>
            {subtitle.regular2}
            <span className="section-subtitle-accent">{subtitle.accent2}</span>
            {subtitle.regular3}
            <span className="section-subtitle-accent">{subtitle.accent3}</span>
            {subtitle.regular4}
          </h3>
        </div>
        <ul className="flex flex-col gap-[10px] md:flex-row justify-between">
          {stats.map((el, idx) => (
            <li key={idx}>
              <p className="flex flex-row gap-[34px] text-[16px] items-baseline md:max-xl:items-center md:gap-[10px]">
                <span className="w-[102px] text-[55px] tracking-[0.55px] font-bold uppercase md:w-auto">
                  {el.left}
                </span>{" "}
                <span className="md:max-xl:w-[86px]">{el.right}</span>
              </p>
            </li>
          ))}
        </ul>
        {notMobile && (
          <div className="w-full">
            <img className="w-full mt-20 md:mt-10" src={mapUrl} alt="map" />
          </div>
        )}
      </Container>
    </section>
  );
};

export default AboutUs;

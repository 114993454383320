import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BravoBurgerNav = ({ clickAction }) => {
  const { t } = useTranslation();
  return (
    <nav className="mb-[100px]">
      <ul className="flex flex-col gap-[10px]">
        <li>
          <Link onClick={clickAction} className="p-[10px]" to="/">
            {t("pageNav.home")}
          </Link>
        </li>
        <li>
          <Link
            onClick={clickAction}
            className="p-[10px] text-myRed font-semibold"
            to="/bravo"
          >
            {t("pageNav.bravo")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default BravoBurgerNav;

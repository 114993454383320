const TechList = ({ left, right, mod }) => {
  return (
    <div
      className={`mt-5 md:flex md:gap-[67px] xl:mt-0 ${
        mod ? "xl:gap-[92px]" : "xl:flex-col xl:gap-0"
      }`}
    >
      <ul
        className={`flex flex-col gap-[10px] mb-[10px] md:w-[calc(50%-5px)] ${
          mod ? "xl:w-[340px]" : "xl:w-full"
        }`}
      >
        {left.map((el, idx) => (
          <li key={idx}>
            {mod ? (
              <p
                className={`flex justify-between ${
                  el.count === "-" ? "text-myGray" : ""
                }`}
              >
                <span>{el.stat}</span>
                <span>{el.count}</span>
              </p>
            ) : (
              el
            )}
          </li>
        ))}
      </ul>
      <ul
        className={`flex flex-col gap-[10px] mb-[10px] md:w-[calc(50%-5px)] ${
          mod ? "xl:w-[340px]" : "xl:w-full"
        }`}
      >
        {right.map((el, idx) => (
          <li key={idx}>
            {mod ? (
              <p
                className={`flex justify-between ${
                  el.count === "-" ? "text-myGray" : ""
                }`}
              >
                <span>{el.stat}</span>
                <span>{el.count}</span>
              </p>
            ) : (
              <p className="flex flex-col xl:flex-row xl:gap-[2px]">
                <span>{el[0]}</span>
                <span>{el[1]}</span>
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TechList;

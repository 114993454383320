import { useTranslation } from "react-i18next";
import Icons from "../Icons/Icons";

const AfterForm = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center w-[200px] mx-auto pb-8">
      <p className="text-[20px] uppercase mb-[10px]">
        {t("afterForm.success")}
      </p>
      <Icons name="checkGreen" className="w-16 h-16 mb-5 mx-auto" />
      <p>{t("afterForm.soon")}</p>
    </div>
  );
};

export default AfterForm;
